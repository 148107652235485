import styled from '@emotion/styled';

const Intro = styled('div')`
  padding-bottom: 1rem;

  h2 {
    font-size: 30pt;
  }
  p { 
    font-size: 20px;
  }

  @media only screen and (max-width : 520px) {
    h2 { font-size: 24pt; }
  }
`;

export default Intro;
