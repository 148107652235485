import React from 'react';
import {css} from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import Intro from '../components/intro';
import MultiLingualApps from '../assets/multiLingualApps.svg';
import SystemsIntegration from '../assets/systemsIntegration.svg';
import WebApplications from '../assets/webApplications.svg';
import MobileApps from '../assets/mobileApps.svg';
import {Link} from 'gatsby';

const Service = styled('div')`
  display: flex;
  margin: 0 0 1rem;
  align-items: center;
  justify-content: space-between;

  &:nth-of-type(odd) div {
    order: 1;
  }

  @media only screen and (max-width : 800px) {
    display: block;
  }
`,
Description = styled('div')`
  order: 2;
  border-radius: 15px;
  padding: 1rem 1rem 2rem;
  width: 48%;
  z-index: 1000;
  background: #F3F3F3;

  h3 { color: #0787CE; }
  ul { margin-bottom: 1rem; }
  p:last-of-type { 
    margin-bottom: 0; 
    padding-bottom: 0;

    a {
      display: inline-block;
      border-radius: 15px;
      padding: 0 10px;
      font-size: 90%;
      background: #0787CE;
      border: 1px solid #0787CE;
      color: #fff;
      text-decoration: none;

      &:hover {
        background: transparent;
        color: #0787CE;
      }
    }
  }

  [data-tip] {
    border-bottom: 1px dotted #ccc;
    cursor: help;
    display: inline-block;
    padding-bottom: 4px !important;
  }

  @media only screen and (max-width : 900px) {
    width: 58%;
  }

  @media only screen and (max-width : 800px) {
    width: 100%;
  }

  @media only screen and (max-width : 500px) {
    padding-top: 50px;
  }
`,
SvgImage = styled('div')`
  order: 3;
  width: 48%;

  img { max-height: 250px; }

  @media only screen and (max-width : 900px) {
    width: 50%;
    float: right;
    margin: 0 0 -10px 1rem;
    position: relative;
    top: -10px;
  }

  @media only screen and (max-width : 500px) {
    width: 95%;
    float: none;
    margin: 0 auto -50px;
    top: 0;
  }
`,
Services = () => (
    <Layout page="Our Services">
      <Intro>
        <h2>If it's online you're after, Talen has the skills</h2>
        <p>Whether it's a mobile app or a custom web application that integrates with
          your existing database, we have the know-how and experience to build what you need.</p>
      </Intro>

      <Service>
        <SvgImage>
          <img src={WebApplications} alt="Web Application Development" />
        </SvgImage>
        <Description>
          <h3>Web Application Development</h3>
          <p>Do you have a paper-based process or a clunky out-dated system that really needs to be online?
          Are you drowning in speadsheets trying to keep track of mountains of data from multiple sources?</p>
          <p><strong>Web Application Development</strong> is our speciality and we've helped businesses of all sizes to
          build custom, efficient solutions enabling their people to focus on and produce the work that they do best.</p>
          <ul>
            <li>We can help design and develop applications based on your specific business requirements and needs.</li>
            <li>With our expertise in data migration, we can craft solutions to enhance your existing systems &mdash;
            ensuring more efficient, reliable and accessible applications.</li>
          </ul>
          <p>See how we've helped <Link to="/work/c-res">C-Res</Link> and <Link to="/work/informia">Informia</Link></p>
        </Description>
      </Service>

      <Service>
        <SvgImage>
          <img src={MobileApps} alt="Mobile Application Development" />
        </SvgImage>
        <Description>
          <h3>Mobile App Development</h3>
          <p>Do you have staff in the field that need to access your internal systems? Do you have a paper-based timesheet or
          job card that has to be manually entered in another system?</p>
          <p><strong>Mobile App Development</strong> is our passion and we've developed several apps to help businesses work
          smarter. We can help you build an app for all major device platforms.</p>
          <ul>
            <li>We can build apps to work with your existing systems to get them mobile</li>
            <li>We can build apps for the global market or specifically for your business only</li>
          </ul>
          <p>Discover how we've helped <Link to="/work/adapt">Adapt</Link> and <Link to="/work/south32">South32</Link></p>
        </Description>
      </Service>

      <Service>
        <SvgImage>
          <img src={SystemsIntegration} alt="Systems Integration" />
        </SvgImage>
        <Description>
          <h3>Systems Integration</h3>
          <p>Do you have multiple systems that don't integrate? Are you manually entering
            data from one system to another? We can help integrate your systems to fully automate
            any manual process to save you time and remove human error.</p>
          <p><strong>Systems Integration</strong> is often where you can gain the most immediate savings for your business by
          eliminating manual data entry.</p>
          <ul>
            <li>We understand that leveraging existing systems and data is crucial for businesses</li>
            <li>It may sound complicated, but systems integration is often simpler than you think</li>
          </ul>
          <p>Review our solutions for <Link to="/work/c-res">C-Res</Link> and <Link to="/work/bhp">BHP</Link></p>
        </Description>
      </Service>

      <Service>
        <SvgImage>
          <img src={MultiLingualApps} alt="Multilingual Applications" />
        </SvgImage>
        <Description>
          <h3>Multilingual Applications</h3>
          <p>Do you have a global business and need a multilingual solution? Do you have an existing
          English only system that needs to be multilingual?</p>
          <p>In today’s global market and diverse population, <strong>Multilingual Applications</strong> are essential for
          ensuring you maximise your reach to both customers and staff. We’ve worked on several projects to implement
          multilingual support and can help you do the same.</p>
          <ul>
            <li>We can build multilingual applications with translations that are easy to manage</li>
            <li>We can help simplify your applications to meet the requirements of your users across multiple geographies &mdash;
            preventing duplication and saving costs</li>
          </ul>
          <p data-tip data-for="spanishTranslation">Vea nuestras soluciones para <Link to="/work/sawu">Sawu</Link> y <Link to="/work/bhp">BHP</Link></p>
          <ReactTooltip id="spanishTranslation" css={css`z-index: 10000;`}>
            <span>See our solutions for Sawu and BHP</span>
          </ReactTooltip>
        </Description>
      </Service>

      <Intro>
        <p css={css`padding-top: 1rem; font-weight: 700; color: #222;`}>In many cases you'll need web, mobile and integration together in one solution. We specialise in
        building solutions across multiple platforms and devices.</p>
      </Intro>

      <section css={css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // padding: 2rem 0 1rem;

        div {
          width: 30%;
          display: flex;
          flex-direction: column;
          
          h3 {
            color: #0787CE;

            @media only screen and (max-width : 910px) {
              display: flex;
              flex-direction: column;
              height: 3rem;
              justify-content: flex-end;
            }
          }

          @media only screen and (max-width : 600px) {
            width: 100%;

            h3 {
              text-align: left;
              display: inline-block;
              height: auto;
            }
          }
        }`
      }>
        <div>
          <h3>Web Design</h3>
          <p>Need a new web site or just a freshen up to your current site? We'll work with you to design and
          build a site from a basic blog through to an online store and more.</p>
        </div>
        <div>
          <h3>Support and Maintenance</h3>
          <p>It's one thing to have an awesome site or application but you want to make sure it stays that way.
            We can provide on-going support and maintenance giving you access to real people, with real answers.</p>
        </div>
        <div>
          <h3>Cloud Hosting</h3>
          <p>Looking to move away from in-house servers and need advice or help getting into the cloud? We
          work in this space on a daily basis and can help you find a solution that will scale to fit your needs
          today and into the future.</p>
        </div>
      </section>
    </Layout>
);

export default Services;
